import React from 'react';

// Material components
import { SvgIcon } from '@material-ui/core';

export default function PolandFlag(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 71 48"
    >
      <g fillRule="nonzero" fill="none"><path d="M64.51.12H7.24C3.7275.12.88 2.9675.88 6.48v17.64h70V6.48A6.36 6.36 0 0064.51.12z" fill="#FFF"/><path d="M.87 40.75c0 3.5125 2.8475 6.36 6.36 6.36H64.5c3.5125 0 6.36-2.8475 6.36-6.36V24.12h-70l.01 16.63z" fill="#ED5565"/></g>
    </SvgIcon>
  );
}

