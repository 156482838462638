import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardMedia, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'none',
    padding: theme.spacing(4, 2)
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain'
  },
}))

const ClientCard = props => {
  const classes = useStyles();
  const { clientName, clientImage, noWrap} = props;
  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={clientImage}/>
      <Typography noWrap={noWrap} color="primary" align="center" variant="subtitle1">
        {clientName}
      </Typography>
    </Card>
  );
};

ClientCard.propTypes = {
  clientName: PropTypes.string.isRequired,
  clientImage: PropTypes.string.isRequired,
  noWrap: PropTypes.bool
};

export default ClientCard;