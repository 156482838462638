import { withStyles, Button } from '@material-ui/core';

const CtaButton = withStyles((theme) => ({
  root: {
    border: 0,
    borderRadius: 0,
    boxShadow: `0 0px 7px 0px ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.dark,
    '&:active': {
      boxShadow: `0 0px 7px 0px ${theme.palette.secondary.main}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      boxShadow: `0 0px 7px 0px ${theme.palette.secondary.light}`,
    },
  },
  
  focusVisible: {
    backgroundColor: theme.palette.secondary.light,
    boxShadow: `0 0px 7px 0px ${theme.palette.secondary.light} !important`
  },

  label: {
    color: theme.palette.primary.dark,
  }
}))(Button);

export default CtaButton;