import React from 'react';
import { makeStyles } from '@material-ui/core';
import KanbanImage from '../../../../../../assets/KanbanImage.svg';

const useClasses = makeStyles(() => ({
  kanban:{
    backgroundImage: `url(${KanbanImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100%',
    minWidth:'500px',
    
    opacity: 0,
    animation: 'fadeInLeft 1.5s 0.5s',
    animationFillMode: 'both',
  }
}))

const Kanban = () => {
  const classes = useClasses()

  return (
    <div className={classes.kanban}>
    </div>
  );
};

export default Kanban;