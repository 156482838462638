import React, { useState } from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import VisibilitySensor from 'react-visibility-sensor';

const useStyles = (textPosition) => makeStyles((theme) => ({
  root:{
    minWidth: theme.spacing(5)
  },
  title:{
    marginBottom: theme.spacing(1)
  },
  description:{
    padding: theme.spacing(1)
  },
  image:{
    width: '100%',
    maxHeight:'400px',
    objectFit:'contain',
    margin: theme.spacing(0,4),
    opacity: 0,
  },
  animateImage:{
    animationFillMode: 'both',
    animation: `${textPosition === 'right' ? 'fadeInRight' : 'fadeInLeft'} 1s 0.4s`
  }
}))


const InfoCard = (props) => {
  const { title, content, textPosition, image } = props;

  const classes = useStyles(textPosition)();
  const [appendAnimationClass, setAppendAnimationClass] = useState(false);
  
  const direction = textPosition === 'right' ? 'row-reverse' : 'row';

  const handleImageVisibilityChange = (isVisible) => {
    if(isVisible){
      setAppendAnimationClass(true);
    }
  }

  return (
    <Grid container alignItems="center" direction={direction} spacing={3} justify="center">
      <Grid item xs={12} sm={7} md={5} lg={6}>
        <div >
          <Typography align="center" variant="h5" color="primary" className={classes.title}>
            {title}
          </Typography>
          <Typography align="justify" variant="body1">
            {content} 
          </Typography>
        </div>
      </Grid>      
      <VisibilitySensor onChange={handleImageVisibilityChange} partialVisibility delayedCall>
        <Grid item xs={12} sm={5} md={5} lg={6} container justify="center">
          <img alt="" src={image} className={clsx(classes.image, appendAnimationClass && classes.animateImage)}/>
        </Grid>
      </VisibilitySensor>
    </Grid>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  textPosition: PropTypes.oneOf(['left', 'right']),
  image: PropTypes.string.isRequired
};

export default InfoCard;