import React from 'react';
import { makeStyles, Grid, Typography, Link } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GitHubIcon from '@material-ui/icons/GitHub';
import { Dribble } from '../../../../icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root:{
    minHeight: '180px',
    background: '#5661c7',
    color: 'rgba(255, 255, 255, 0.9)'
  },
  content: {
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  wrap: {
    margin: theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  copyrights: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right'
    }
  },
  socialLinks:{
    height: '100%'
  },
  
  socialLinkIcon: {
    cursor: 'pointer',
    margin:  theme.spacing(0, 1),
    color: 'rgba(255, 255, 255, 0.9)',
    '&:hover':{
      color: theme.palette.secondary.main
    }
  },
  link:{
    color: 'rgba(255, 255, 255, 0.9)',
    
    '&:hover':{
      color: theme.palette.secondary.main,
      cursor: 'pointer',
      textDecoration: 'none'
    }
  }
}));

const Footer = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid container item xs={12} lg={10} xl={8} className={classes.content} justify="space-between">
        <Grid item xs={12} md className={classes.wrap}>
          <Grid container direction="column">
            <Grid item>
              <Typography gutterBottom variant="h6">
              Omnikom sp. z o.o.     
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Świeradowska 47<br/>
                02-662 Warszawa<br/>
                {t('footer.email')}: info@omnikom.pl
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md className={classes.wrap}>
          
          <Grid container justify="center" alignItems="flex-end" className={classes.socialLinks}>
            <FacebookIcon
              className={classes.socialLinkIcon}
            /> 
            <YouTubeIcon
              className={classes.socialLinkIcon}
            /> 
            <LinkedInIcon
              className={classes.socialLinkIcon}
            /> 
            <Dribble
              className={classes.socialLinkIcon}
            /> 
            <GitHubIcon
              className={classes.socialLinkIcon}
            /> 
          </Grid>
        </Grid>
        <Grid item  xs={12} md className={classes.wrap}>
          <Grid container direction="column" justify="flex-end"  className={classes.copyrights}>
            <Grid item>
              <Link href={i18n.language === 'pl' ? '/PolitykaPrywatnosci.pdf' : '/PrivacyPolicy.pdf'} target="_blank" className={classes.link}>
                <Typography>
                  {t('footer.privacyPolicy')}
                </Typography>
              </Link>
              <Typography>
                @ {new Date().getFullYear()} {t('footer.rights')}
              </Typography>
              <Link target="_blank" className={classes.link} href="https://stories.freepik.com/">
                <Typography>
                  Illustrations by Freepik Stories
                </Typography>
              </Link>
              
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;