import { useState, useEffect } from 'react'

const useScrollPositionGreaterThan = (position) => {

  const useScrollHandler = () => {
    
    const initialCheck = window.scrollY > position
    const [scroll, setScroll] = useState(initialCheck)
  
    useEffect(() => {
      const onScroll = () => {
        const scrollCheck = window.scrollY > position
        if (scrollCheck !== scroll) {
          setScroll(scrollCheck)
        }
      }
  
      document.addEventListener('scroll', onScroll)
  
      return () => {
        document.removeEventListener('scroll', onScroll)
      }
    }, [scroll])
  
    return scroll
  }
  return useScrollHandler();
}

export default useScrollPositionGreaterThan;
