import React from 'react';
import { makeStyles, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  list:{
    width: '250px'
  },
  icon:{
    color: theme.palette.text.secondary
  }
}));

const SideBar = (props) => {

  const classes = useStyles();
  const {open, onClose, menuList} = props;
 

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <List className={classes.list}>
        {menuList.map((menuItem) => (
          <ListItem button key={menuItem.name} onClick={menuItem.onClick}>
            <ListItemIcon className={classes.icon}>{menuItem.icon}</ListItemIcon>
            <ListItemText primary={menuItem.name} />
          </ListItem>
        ))}
      </List>
    </Drawer> 
  );
};

SideBar.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  menuList: PropTypes.array.isRequired,
};

export default SideBar;