import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import Divider from '../../assets/Divider.svg'; 

const useStyles = makeStyles((theme) => ({

  root:{
    marginBottom: theme.spacing(4)
  },
  divider:{
    height: '40px',
    marginBottom: theme.spacing(1)
  },
  title:{
    position: 'relative',
    '&::after':{
      content: '""',
      position: 'absolute',
      height: '2px',
      width: '100%',
      bottom: '-3px',
      left: 0,
      right: 0,
      background: theme.palette.secondary.main,
      boxShadow: `0 0px 5px 0px ${theme.palette.secondary.main}`,
    }
  }
  
}));

const SectionTitle = (props) => {
  const classes = useStyles();
  const { title } = props;

  return (
    <div>
      <Grid container alignItems="center" direction="column" className={classes.root}>
        <img alt="..." src={Divider} className={classes.divider}/>
        <Typography color="primary" align="center" variant="h5" className={classes.title}>
          {title}
        </Typography>
      </Grid>
    </div>
  );
};

SectionTitle.propTypes = {  
  title: PropTypes.string.isRequired
};

export default SectionTitle;