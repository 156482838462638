import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Avatar, makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: '75px',
    width: '75px',
    marginBottom: theme.spacing(3),
    boxShadow: '0 10px 25px -12px rgba(0, 0, 0, 0.56), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 6px 8px -5px rgba(0, 0, 0, 0.2)'
  },
  opinion: {
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(4),
    borderColor: theme.palette.secondary.main,
  }
}));

const ClientOpinion = props => {
  const { opinion, image, name } = props;
  const classes = useStyles();
  return (
    <Grid container alignItems="center" direction="column">
      <Grid item xs={12}>
        <Paper variant="outlined" square className={classes.opinion}>
          <Typography variant="body1" color="textPrimary" align="justify">
            {opinion}
          </Typography>
        </Paper>
      </Grid>
      <Avatar src={image} className={classes.avatar}/>
      <Typography color="textSecondary" variant="h6" gutterBottom>
        {name}
      </Typography>
    </Grid>
  );
};

ClientOpinion.propTypes = {
  opinion: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default ClientOpinion;