import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@material-ui/core';
import { SectionTitle, CtaButton } from '../../../../components';
import { Element } from 'react-scroll';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    background: '#fafafa',
    boxShadow: '0px 0px 4px -1px rgba(0,0,0,0.2), 0px 0px 5px 0px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12);',
    
  },
  content: {
    margin: theme.spacing(8, 0)
  },
  textField: {
    width: '100%',
    borderRadius: 0,
    background: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.light
  },
  input: {
    borderRadius: 0
  },
  formWrapper:{
    padding: theme.spacing(3)
  },
  form: {
    backgroundColor: '#fff',
    borderRadius: 0,
    '& fieldset': {
      borderColor: theme.palette.secondary.main,
      transition: 'border-color 0.5s ease'
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.secondary.dark} !important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.secondary.main} !important`,
    }
  },
  formInputLabel:{
    color: theme.palette.text.hint
  },
  button: {
    width: '110px',
    height: '40px',
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.main
  },
  formTextHelper:{
    position: 'absolute',
    bottom: '-20px'
  }

}));

const ContactSection = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [formDataErrors, setFormDataErrors] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [formDataTouched, setFormDataTouched] = useState({
    name: false,
    email: false,
    message: false
  });
  const classes = useStyles(); 
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const validateFields = () => {
    const errors = {
      name: '',
      email: '',
      message: ''
    }
    
    if(!formData.name.length){
      errors.name = t('contactSection.errors.name');
    }

    const emailRegex = /\S+@\S+\.\S+/;

    if(!emailRegex.test(formData.email)){
      errors.email = t('contactSection.errors.email');
    }

    if(formData.message.length < 8){
      errors.message = t('contactSection.errors.message');
    }

    setFormDataErrors(errors);

    if(errors.name || errors.email || errors.message){
      return false;
    }
    return true;
  }
  
  useEffect(() => {
    validateFields();
  }, [formData, i18n.language]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });

    setFormDataTouched({
      ...formDataTouched,
      [event.target.name]: true
    })
  };

  const handleSubmitForm = () => {

    setFormDataTouched({
      name: true,
      email: true,
      message: true
    })

    if(!validateFields()){
      return;
    }


   
    const database = window.firebase.database();
    database.ref(`contacts/${Date.now()}`).set(formData, (err) => {
      if(err){
        console.error(err);
        enqueueSnackbar(t('contactSection.snackbarTextFailed'), 
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            }
          }
        );
        return;
      }
      enqueueSnackbar(t('contactSection.snackbarTextSuccess'), 
        {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
      );
      setFormData({
        name: '',
        email: '',
        message: ''
      })
      setFormDataTouched({
        name: false,
        email: false,
        message: false
      })
    });


 
  }

  return (
    <Element name="ContactSection">
      <form>
        <Grid container >
          <Grid className={classes.root} item container justify="center">
            <Grid className={classes.content} item xs={12} lg={10} xl={8} container justify="center">
              <Grid item xs={12}>
                <SectionTitle title={t('sectionTitle.contact')}/>
              </Grid>
              <Grid item xs={12} sm={10} md={8} className={classes.formWrapper}>
                <Grid container justify="center" alignItems="center" spacing={4}>
                  <Grid item  xs={12} sm={6}>  
                    <FormControl error={formDataTouched.name && !!formDataErrors.name} fullWidth variant="outlined">
                      <InputLabel  htmlFor="form-name" className={classes.formInputLabel}>{t('contactSection.formNames.name')}</InputLabel>
                      <OutlinedInput error={formDataTouched.name && !!formDataErrors.name} id="form-name" value={formData.name} name="name" onChange={handleChange} label={t('contactSection.formNames.name')} className={classes.form}/>
                      {formDataTouched.name && (
                        <FormHelperText id="form-name" className={classes.formTextHelper}>{formDataErrors.name}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl  error={formDataTouched.email && !!formDataErrors.email} fullWidth variant="outlined">
                      <InputLabel htmlFor="form-email" className={classes.formInputLabel}>{t('contactSection.formNames.email')}</InputLabel>
                      <OutlinedInput error={formDataTouched.email && !!formDataErrors.email} id="form-email" value={formData.email} name="email" onChange={handleChange} label={t('contactSection.formNames.email')} className={classes.form}/>
                      {formDataTouched.email && (
                        <FormHelperText id="form-email" className={classes.formTextHelper}>{formDataErrors.email}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl  error={formDataTouched.message && !!formDataErrors.message} fullWidth variant="outlined">
                      <InputLabel htmlFor="form-message" className={classes.formInputLabel}>{t('contactSection.formNames.message')}</InputLabel>
                      <OutlinedInput error={formDataTouched.message && !!formDataErrors.message} id="form-message" value={formData.message} name="message" onChange={handleChange} label={t('contactSection.formNames.message')} multiline={true} rows={4} rowsMax={20} className={classes.form}/>
                      {formDataTouched.message && (
                        <FormHelperText id="form-message" className={classes.formTextHelper}>{formDataErrors.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <CtaButton disabled={!!((formDataTouched.name && formDataErrors.name) || (formDataTouched.email && formDataErrors.email) || (formDataTouched.message && formDataErrors.message))} variant="contained" color="secondary" className={classes.button} onClick={handleSubmitForm}>
                    {t('contactSection.button')}
                  </CtaButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Element>
  );
};

export default ContactSection;