import React from 'react';
import { makeStyles, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { SectionTitle, ParallaxCache } from '../../../../components';
import ParallaxImage from '../../../../assets/ParallaxImage.png';
import TeamMember from './components/TeamMember';
import { ParallaxBanner } from 'react-scroll-parallax';
import Krzys from '../../../../assets/Krzys.jpg';
import Karol from '../../../../assets/Karol.jpg';
import Marcin from '../../../../assets/Marcin.jpg';
import Ksenia from '../../../../assets/Ksenia.jpg';
import Noemi from '../../../../assets/Noemi.jpg';
import Grzes from '../../../../assets/Grzes.jpg';
import Kasia from '../../../../assets/Kasia.jpg';
import Tola from '../../../../assets/Tola.jpg';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(8, 0),
  },
  root: {
    position: 'relative'
  },
  teamWrapper: {
    margin: theme.spacing(0, 2)
  }
}));

const TeamSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  return (
    <Element name="TeamSection">
      <>
        <ParallaxBanner
          layers={[
            {
              image: ParallaxImage,
              amount: 0.2
            },
          ]}
          style={ {height: '100%'}}
          disabled={isMobile}
        >
          <ParallaxCache/>
          <Grid className={classes.root} container justify="center">
            <Grid className={classes.content} item xs={12} sm={12} md={12} lg={10} xl={8} container justify="center" >
              <Grid item xs={12}>
                <SectionTitle title={t('sectionTitle.team')}/>
              </Grid>
              <Grid container item xs={12} md={11} lg={10} justify="center" spacing={5} className={classes.teamWrapper}> 
                <Grid item>
                  <TeamMember
                    name="Marcin Kamiński"
                    image={Marcin}
                    position="Team Leader"
                  />
                </Grid>
                <Grid item>
                  <TeamMember
                    name="Krzysztof Kamiński"
                    image={Krzys}
                    position="Developer"
                  />
                </Grid>
                <Grid item>
                  <TeamMember
                    name="Karol Kamiński"
                    image={Karol}
                    position="Developer"
                  />
                </Grid> 
                <Grid item>
                  <TeamMember
                    name="Grzegorz Kacprowicz"
                    image={Grzes}
                    position="Developer"
                  />
                </Grid>
                <Grid item>
                  <TeamMember
                    name="Ksenia Kamińska"
                    image={Ksenia}
                    position="UX & Frontend Developer"
                  />
                </Grid>
                <Grid item>
                  <TeamMember
                    name="Noemi Kamińska"
                    image={Noemi}
                    position="Developer"
                  />
                </Grid> 
                <Grid item>
                  <TeamMember
                    name="Katarzyna Bukowska"
                    image={Kasia}
                    position="Web Admin"
                  />
                </Grid>
                <Grid item>
                  <TeamMember
                    name="Teofila Kamińska"
                    image={Tola}
                    position="Helping Hand"
                  />
                </Grid> 
              </Grid>
            </Grid>
          </Grid> 
        </ParallaxBanner>
      </>
    </Element>
  );
};

export default TeamSection;