import React from 'react';

// Material components
import { SvgIcon } from '@material-ui/core';

export default function UKFlag(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 71 48"
    >
      <g fillRule="nonzero" fill="none"><path d="M30.57.68h-3.13V15l-25-12.5a6.62 6.62 0 00-2 4.24l19.2 9.6h-7L.41 10.23V12l8.73 4.36H.41v3.13H30.6L30.57.68zM68.23 45.89a6.62 6.62 0 002.12-4.19L50.93 32h7l12.46 6.23v-1.75l-9-4.48h9v-3.13H40v18.79h3.13V33.34l25.1 12.55zM43.1.68H40v18.79h30.42v-3.13h-9l9-4.48V7.29v-.67l-19.43 9.71h-7L69.36 3.64a6.68 6.68 0 00-1-1.23L43.1 15V.68zM.39 28.86V32h8.73L.39 36.36V41.59l19.2-9.6h7L1.41 44.58a6.66 6.66 0 001 1.24l25-12.49v14.32h3.13V28.86H.39z" fill="#FFF"/><path fill="#EC5565" d="M70.39 28.86v-9.39H39.97V.68h-9.4v18.79H.39v9.39h30.18v18.79h9.4V28.86z"/><path d="M19.61 16.34L.41 6.74V10.22l12.23 6.11 6.97.01zM50.93 16.34l19.43-9.71a6.6 6.6 0 00-1.06-3L43.93 16.32l7 .02zM50.93 32l19.42 9.71a6.72 6.72 0 000-.7v-2.78L57.93 32h-7zM19.61 32L.41 41.6a6.6 6.6 0 001 3L26.62 32h-7.01z" fill="#EC5565"/><path d="M43.1 15L68.26 2.42A6.61 6.61 0 0064.42.68H43.1V15zM70.39 36.47v-4.48h-8.96zM70.39 16.34v-4.48l-8.96 4.48zM2.46 45.82a6.61 6.61 0 004.57 1.83h20.41V33.33L2.46 45.82zM43.1 33.33v14.32h20.65a6.61 6.61 0 004.48-1.75L43.1 33.33zM27.44.68H6.36A6.61 6.61 0 002.44 2.5l25 12.5V.68zM.39 31.99v4.36l8.72-4.36zM.39 11.97v4.37h8.72z" fill="#4658A9"/></g>
    </SvgIcon>
  );
}

