import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { SectionTitle, InfoCard } from '../../../../components';
import MobileApp from '../../../../assets/MobileApp.svg';
import Blockchain from '../../../../assets/Blockchain.svg';
import MachineLearning from '../../../../assets/MachineLearning.svg';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    background: '#fff',
    boxShadow: '0px 0px 4px -1px rgba(0,0,0,0.2), 0px 0px 5px 0px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12);'
  },
  content: {
    margin: theme.spacing(8, 0),
  },
}));

const OfferSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <Element name="OfferSection">
      <Grid container>
        <Grid className={classes.root} container item justify="center">
          <Grid className={classes.content} item xs={12} lg={10} xl={8} container justify="center" spacing={6}>
            <Grid item xs={12}>
              <SectionTitle title={t('sectionTitle.offer')}/>
            </Grid>
            <Grid item xs={12}>
              <InfoCard
                textPosition="right"
                title={t('infoCard.mobile.title')}
                content={t('infoCard.mobile.content')}
                image={MobileApp}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoCard
                textPosition="left"
                title={t('infoCard.blockchain.title')}
                content={t('infoCard.blockchain.content')}
                image={Blockchain}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoCard
                textPosition="right"
                title={t('infoCard.aiml.title')}
                content={t('infoCard.aiml.content')}
                image={MachineLearning}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Element>
  );
};

export default OfferSection;