import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Home } from './views';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home}/>
        <Redirect to="/"/>
      </Switch> 
    </BrowserRouter>
  );
};

export default Routes;