import React from 'react';
import { Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { CtaButton } from '../../../../../../components';
import { scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';


const useClasses = makeStyles((theme) => ({
  root:{
    margin: theme.spacing(0, 3),
    textAlign: 'right'
  },
  title:{
    position: 'relative',
    marginBottom: theme.spacing(1.5),
    '&::after':{
      content: '""',
      position: 'absolute',
      height: '3px',
      width: '100%',
      bottom: '-3px',
      left: 0,
      right: 0,
      background: theme.palette.secondary.main,
      boxShadow: `0 0px 7px 1px ${theme.palette.secondary.main}`,
    },
      
  
  },
  description:{
    marginBottom: theme.spacing(1) ,
     
  },
  ctaButton:{
    minWidth: '150px'
  },
  titleSpan:{
    whiteSpace: 'nowrap'
  }
}));

const DescriptionBox = () => {

  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useClasses();

  const handleClick = () => scroller.scrollTo(
    'ContactSection',
    {
      duration: 1000,
      smooth: 'easeInOutCubic',
      offset: isMobile ? -56 : -64
    }
  );

  return (
    <div className={classes.root}>
      <Typography variant="h5"  color="primary" align="right" className={classes.title}>
        <span className={classes.titleSpan}>
          {t('landingPage.title.partOne')}
        </span>
        <span> </span>
        <span className={classes.titleSpan}>
          {t('landingPage.title.partTwo')}
        </span>
      </Typography> 
      <Typography variant="subtitle1" color="primary" align="right" className={classes.description}>
        {t('landingPage.description')}
      </Typography>
      <CtaButton variant="contained" color="secondary" className={classes.ctaButton} onClick={handleClick}>
        {t('landingPage.button')}
      </CtaButton>
    </div>
  );
};

export default DescriptionBox;