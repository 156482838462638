import React from 'react';
import { Grid, makeStyles, Hidden } from '@material-ui/core';
import Background from '../../../../assets/Background2.svg';
import { DescriptionBox, Kanban } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Background}), linear-gradient(120deg, #b1e4f5, #b48cfb)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: '800px',
    [theme.breakpoints.down('md')]: {
      minHeight: '600px',
    },
  },
  content:{ 
    margin: theme.spacing(8, 0)
    
  },
  noOverflow: {
    overflow: 'hidden'
  },
  descriptionBoxAnimation: {
    opacity: 0,
    animation: 'fadeInUpRight 1s 0.2s',
    animationFillMode: 'both',
  }
}));


const LandingPageSection = () => {
  
  const classes = useStyles();

  return ( 

    <Grid className={classes.root} container justify="center">
      <Grid className={classes.content} item xs={12} sm={12} md={12} lg={10} xl={8} container justify="center" spacing={2}>
        <Grid item xs={12} sm={7} md={5} container justify="flex-end" alignItems="center" className={classes.descriptionBoxAnimation}>
          <DescriptionBox/>
        </Grid>
        <Hidden xsDown>
          <Grid item sm={5} md={7} className={classes.noOverflow}>
            <Kanban/>
          </Grid>
        </Hidden> 
        
      </Grid>
    </Grid> 
  );
};

export default LandingPageSection;