import React from 'react';
import { makeStyles, Grid, useTheme, useMediaQuery, Typography, Card, CardMedia } from '@material-ui/core';
// import Slider from 'react-slick';
import Neonet from '../../../../assets/neonet.png';
import Euro from '../../../../assets/euro.png';
import Pge from '../../../../assets/pge.png';
import Longterm from '../../../../assets/longterm.png';
// import ClientOpinion from './components/ClientOpinion';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { CtaButton, SectionTitle } from '../../../../components';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { FilterNone } from '@material-ui/icons';
import { ClientCard } from './components';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    background:'#fff'
  },
  content: {
    margin: theme.spacing(8, 0),
  },
  text: {
    textAlign: 'justify',
    textAlignLast: 'justify'
  },
  button: {
    width: '180px',
    height: '60px'
  },
  wrap: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 3),
    },
    marginTop: theme.spacing(3),
    padding: theme.spacing(0, 4)
  },
  sliderArrow:{
    boxShadow: '0 0px 6px -1px #28fcc0',
    minWidth: '30px',
    width: '30px',
    padding: '0px',
    height: '30px',
    fontSize: '25px',
  },
  sliderArrowWrapper:{
    position: 'absolute',
    top: '50%',
    display: 'block',
    transform: 'translate(0, -50%)',
    '&.left':{
      left: '-30px',
    },
    '&.right':{
      right: '-30px',
    }
  }
}));


const SliderNextArrow = (props) => {
  const { classes, onClick } = props;
  return (
    <div className={clsx(classes.sliderArrowWrapper, 'right')}>
      <CtaButton variant="contained" color="secondary" onClick={onClick} className={classes.sliderArrow}>
        <KeyboardArrowRightIcon color="inherit" fontSize="inherit"/>
      </CtaButton>
    </div>
  );
}

const SliderPrevArrow = (props) => {
  const { classes, onClick } = props;
  return (
    <div className={clsx(classes.sliderArrowWrapper, 'left')}>
      <CtaButton variant="contained" color="secondary" onClick={onClick} className={classes.sliderArrow}>
        <KeyboardArrowLeftIcon color="inherit" fontSize="inherit"/>
      </CtaButton>
    </div>
  );
}


const clients = [
  {name: 'Neonet', image: Neonet},
  {name: 'RTV Euro AGD', image: Euro, noWrap:true},
  {name: 'Polska Grupa Elektroenergetyczna', image: Pge},
  {name: 'Longterm', image: Longterm},
]

const ClientsSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const { t } = useTranslation();

  
  return (
    <Element name="ClientsSection">
      <Grid className={classes.root} container justify="center">
        <Grid className={classes.content} item xs={12} lg={10} xl={8} container justify="center" alignItems="center" > 
          <Grid item xs={12}>
            <SectionTitle title={t('sectionTitle.clients')}/>
          </Grid>
          {clients.map((client) => (
            <Grid key={client.name} item xs={12} sm={4} md={3} lg className={classes.wrap}>
              <ClientCard noWrap={client.noWrap} clientName={client.name} clientImage={client.image}/>
            </Grid>
          ))}
          {/*<Slider 
              dots= {false}
              infinite
              speed={1200}
              autoplay
              autoplaySpeed={3000}
              pauseOnHover
              slidesToShow= {1}
              slidesToScroll= {1}
              nextArrow={<SliderNextArrow classes={classes} />}
              prevArrow={<SliderPrevArrow classes={classes} />}
              arrows={!isMobile}
            >
              <div>
                <ClientOpinion opinion={t('clientsSection.kaminski')}
                  image={Neonet}
                  name="Neonet"/>
              </div>
              <div>
                <ClientOpinion opinion={t('clientsSection.rokicki')}
                  image={Euro}
                  name="RTV Euro AGD"/>
              </div>
              <div>
                <ClientOpinion opinion={t('clientsSection.rokicki')}
                  image={Pge}
                  name="Polskie Sieci Elektroenergetyczne"/>
              </div>
              <div>
                <ClientOpinion opinion={t('clientsSection.rokicki')}
                  image={Longterm}
                  name="Longterm"/>
              </div>
            </Slider> */}
        </Grid>
      </Grid>
    </Element>
  );
};

SliderNextArrow.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

SliderPrevArrow.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func
};


export default ClientsSection;