import React, { useRef, useState } from 'react';
import { AppBar, Toolbar, makeStyles, Typography, Grid, Menu, MenuItem, Hidden, useMediaQuery, useTheme} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../../../assets/Logo.png'; 
import LanguageIcon from '@material-ui/icons/Language';
import { PolandFlag, UKFlag } from '../../../../icons';
import clsx from 'clsx';
import useScrollPositionGreaterThan from '../../../../hooks/useScrollPositionGreaterThan';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  noShadow:{
    boxShadow: 'none'
  },
  logo:{
    width: '175px',
    userSelect: 'none'
  },
  menuItem:{
    cursor: 'pointer',
    margin: theme.spacing(0, 5),
    '&:hover':{
      color: theme.palette.secondary.main
    },
    userSelect: 'none'
  },
  languageIcon: {
    cursor: 'pointer',
    margin: '12px',
    [theme.breakpoints.up('md')]: {
      marginRight: '0px',
    },
    '&:hover':{
      color: theme.palette.secondary.main
    }
  },
  menuIcon:{
    cursor: 'pointer',
    margin: '12px',
    marginRight: '0px',
    '&:hover':{
      color: theme.palette.secondary.main
    }
  },
  appBar: {
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'linear-gradient(to right, #b1e4f5, #b48cfb)',
      zIndex: '-1',
      transition: 'opacity 0.5s ease',
      opacity: 0,
    }
  },
  appBarContainedBackground:{
    '&::before': {
      opacity: 1
    }
  }
}));

const TopBar = (props) => {

  const classes = useStyles();
  const {handleDrawerOpen, menuList} = props;

  const anchorEl = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const shouldShowContainedBackground = useScrollPositionGreaterThan(matches? 500 : 700);
  const { i18n } = useTranslation();
  
  

  const handleLanguageMenuOpen = () => {
    setIsMenuOpen(true);
  }
  const handleLanguageMenuClose = () => {
    setIsMenuOpen(false);
  }
  const handleChangeLanguage = lng => () => {
    i18n.changeLanguage(lng);
    handleLanguageMenuClose();
  };
  
  const renderMenu = (
    <Menu
      anchorEl={anchorEl.current}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem onClick={handleChangeLanguage('en')}> 
        <UKFlag className={classes.flagIcon}/>
      </MenuItem>
      <MenuItem onClick={handleChangeLanguage('pl')}>
        <PolandFlag className={classes.flagIcon}/></MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" color="transparent" className={clsx(classes.noShadow,classes.appBar, shouldShowContainedBackground && classes.appBarContainedBackground)}>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12} lg={10} xl={8}>
            <Toolbar>
              <img alt="Omnikom" src={Logo} className={classes.logo}/>
              <div className={classes.grow}/>
              <Hidden smDown>
                {menuList.map((menuItem) => (
                  <Typography variant="body1" color="primary" key={menuItem.name} className={classes.menuItem} onClick={menuItem.onClick}>
                    {menuItem.name}
                  </Typography>
                ))}
              </Hidden> 
              <LanguageIcon  
                color="primary" 
                className={classes.languageIcon}
                onClick={handleLanguageMenuOpen}
                ref={anchorEl}
              /> 
              <Hidden mdUp> 
                <MenuIcon 
                  color="primary"
                  onClick={handleDrawerOpen}
                  className={classes.menuIcon}
                /> 
              </Hidden>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
      {renderMenu}
    </>
  );
};

TopBar.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
  menuList: PropTypes.array.isRequired,
};

export default TopBar;