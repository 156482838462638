import React, { useState } from 'react';
import { makeStyles, Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { CtaButton } from '../../../../components';
import { scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: { 
    zIndex: 1,
    background:'#fafafa',
    boxShadow: '0px 0px 4px -1px rgba(0,0,0,0.2), 0px 0px 5px 0px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12);'
  },
  content:{
    margin: theme.spacing(8, 0),
  },
  text:{
    textAlign: 'justify',
    textAlignLast: 'justify'
  },
  button:{
    width: '180px',
    height: '60px'
  },
  wrap: {
    margin: theme.spacing(2, 4),
    opacity: 0
  },
  animateText: {
    animationFillMode: 'both',
    animation: 'fadeInRightIntense 1s 0.2s'
  },
  animateButton: {
    animationFillMode: 'both',
    animation: 'fadeInLeftIntense 1s 0.4s'
  }
}));

const InvitingSection = () => {
  
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const [appendAnimationClass, setAppendAnimationClass] = useState(false);

  const handleImageVisibilityChange = (isVisible) => {
    if(isVisible){
      setAppendAnimationClass(true);
    }
  }

  const handleClick = () => scroller.scrollTo(
    'ContactSection',
    {
      duration: 1000,
      smooth: 'easeInOutCubic',
      offset: isMobile ? -56 : -64
    }
  );
      
  return ( 
    <Grid container>
      <Grid className={classes.root} container item justify="center">
        <VisibilitySensor onChange={handleImageVisibilityChange} partialVisibility delayedCall>
          <Grid className={classes.content} item xs={12} lg={10} xl={8} container justify="center" alignItems="center"> 
            <Grid item >
              <div className={clsx(classes.wrap, appendAnimationClass && classes.animateText)}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="h5" color="textSecondary" className={classes.text}>
                      {t('invitationSection.partOne')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" color="textSecondary" className={classes.text}>
                      {t('invitationSection.partTwo')}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item>
              <div className={clsx(classes.wrap, appendAnimationClass && classes.animateButton)}>
                <Grid container >
                  <Grid item >
                    <CtaButton variant="contained" color="secondary" className={classes.button} size="large" onClick={handleClick}>
                      {t('invitationSection.button')}
                    </CtaButton>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </VisibilitySensor>
      </Grid> 
    </Grid>
  );
};

export default InvitingSection;