import React, { useState } from 'react';
import {makeStyles, createMuiTheme, MuiThemeProvider, CssBaseline, useMediaQuery } from '@material-ui/core';
import { TopBar, SideBar, Footer } from './components';
import TeamIcon from '@material-ui/icons/People';
import MissionIcon from '@material-ui/icons/FlagOutlined';
import FAQIcon from '@material-ui/icons/HelpOutlineOutlined';
import ContactIcon from '@material-ui/icons/AlternateEmailOutlined';
import PropTypes from 'prop-types';
import { ParallaxProvider } from 'react-scroll-parallax'; 
import { scroller } from 'react-scroll';
import { SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#183796',
      dark: '#102669'
    },
    secondary: {
      main: '#28fcc0',
      light: '#b0fff4'
    },
    text:{
      primary: 'rgba(0, 0, 0, 0.70)',
      secondary: '#183796'
    }
  },
});

const useStyles = makeStyles(() => ({
  content: {
  },
  success: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.dark,
    borderRadius: '0px'
  },
  error: {
    borderRadius: '0px'
  }
}))

const Main = (props) => {
 
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); 
  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  }
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  }
  const scrollOptions = {
    duration: 1000,
    smooth: 'easeInOutCubic',
    offset: isMobile ? -56 : -64
  }

  const menuList = [
    {name: t('menu.offer'), icon: <MissionIcon/>, onClick: () => {handleDrawerClose(); scroller.scrollTo('OfferSection', scrollOptions)}},
    {name: t('menu.team'), icon: <TeamIcon/>, onClick: () => {handleDrawerClose(); scroller.scrollTo('TeamSection', scrollOptions)}},
    {name: t('menu.clients'), icon: <FAQIcon/>, onClick: () => {handleDrawerClose(); scroller.scrollTo('ClientsSection', scrollOptions)}},
    {name: t('menu.contact'), icon: <ContactIcon/>, onClick: () => {handleDrawerClose(); scroller.scrollTo('ContactSection', scrollOptions)}}
  ];

  return (
    <ParallaxProvider>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error
          }}
        >
          <CssBaseline/>
          <CookieConsent
            buttonText={t('cookieBar.button')}
            style={{ 
              background: theme.palette.primary.dark,
              justifyContent: isMobile ? 'center' : 'space-between' 
            }}
            buttonStyle={{
              color: theme.palette.text.primary,
              background: theme.palette.secondary.main,
              fontSize: '14px',
              fontFamily: 'Roboto',
              fontWeight: 500,
              boxShadow: '0 0px 7px 0px #28fcc0'
            }}
          
          >
            {t('cookieBar.content')}
          </CookieConsent>
          <TopBar handleDrawerOpen={handleDrawerOpen} menuList={menuList}/>
          <SideBar open={isDrawerOpen} onClose={handleDrawerClose} menuList={menuList}/>
          <main className={classes.content}>
            {props.children}
          </main>
          <Footer/>
        </SnackbarProvider>
      </MuiThemeProvider>
    </ParallaxProvider>
  );
};

Main.propTypes = {
  children: PropTypes.object,
}

export default Main;