import React from 'react';
import { LandingPageSection, OfferSection, TeamSection, InvitingSection, ClientsSection, ContactSection } from './views';

const Home = () => {
  return (
    <div>
      <LandingPageSection/>
      <OfferSection/>
      <TeamSection/>
      <InvitingSection/>
      <ClientsSection/>
      <ContactSection/>
    </div>
  );
};

export default Home;