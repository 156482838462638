import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width:'250px',
    marginTop: '41px',
  },
  avatar:{
    height: '125px',
    width: '125px',
    marginBottom: theme.spacing(3),
    marginTop: '-65px',
    boxShadow: '0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
}));

const TeamMember = props => {
  const { image, name, position } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container alignItems="center" direction="column" >
        <Avatar alt="Remy Sharp" src={image} className={classes.avatar}/>
        <Typography color="textSecondary" variant="h6" gutterBottom>
          {name}
        </Typography>
        <Typography variant="body1">
          {position}
        </Typography>
      </Grid>
    </div>
  );
};

TeamMember.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired
};

export default TeamMember;